import React, { useState, useContext, useRef } from "react";
import {
  Form,
  Input,
  Title,
  Text,
  Label,
  Select,
  PrimaryButton,
} from "../../theme//theme.js";
import { Formik, useFormikContext, useField } from "formik";
import * as yup from "yup";
import styled from "styled-components";
import Context from "../../store/context";
import FirebaseService from "../../services/FirebaseService";

const Images = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  border: 1px solid ${({ theme }) => theme.darkGray};
  margin: 5px;
  & div {
    position: relative;
    max-width: 33%;
    cursor: pointer;
    & img {
      max-height: 40px;
      margin: 5px;
    }
    & .closeBtn {
      position: absolute;
      z-index: 10;
      opacity: 0;
      left: 40%;
      font-size: 1rem;
      line-height: 40px;
      text-align: center;
    }
    &:hover {
      & img {
        opacity: 0.5;
      }
      & .closeBtn {
        opacity: 1;
      }
    }
  }
`;

const DeleteButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.white};
  border-color: ${({ theme }) => theme.negRed};
  color: ${({ theme }) => theme.negRed};
  align-self: flex-end;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1rem;
`;

function RuleForm(props) {
  const context = useContext(Context);

  const [startDate, setStartDate] = useState(new Date());

  console.log(props.init);

  const submitForm = async (values) => {
    let res;
    try {
      context.actions({
        type: "setState",
        payload: { ...context.state, isFetching: true },
      });

      res = await FirebaseService.updateRule(values);

      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isFetching: false,
          isModalOpen: false,
          modalComponent: "",
        },
      });
      console.log(res);
      props.refresh(Math.random());
    } catch (e) {
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isError: true,
          errorMsg: e.message,
          isModalOpen: false,
          modalComponent: "",
        },
      });
    }
  };

  const deleteRule = async (id) => {
    try {
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isFetching: true,
          isModalOpen: true,
          modalComponent: "",
        },
      });
      let res = await FirebaseService.deleteRule(id);
      props.refresh(Math.random());
      console.log(res);
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isFetching: false,
          isModalOpen: false,
          modalComponent: "",
        },
      });
    } catch (e) {
      console.log(e);
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isError: true,
          errorMsg: e.message,
        },
      });
    }
  };

  return (
    <>
      <Title>{props.title}</Title>

      {/* FORMIK */}
      <Formik
        initialValues={
          !props.init
            ? {
                amount: 0,
                type: "Electricity",
                description: "",
                paidAt: startDate,
                source: "Amazon",
              }
            : props.init
        }
        validate={(values) => {
          console.log("VALIDATE : ", values);
        }}
        onSubmit={(values) => {
          submitForm(values);
        }}
        enableReinitialize={false}
        validationSchema={yup.object().shape({
          amount: yup.number().required(),
          description: yup.string().required(),
        })}
      >
        {({
          touched,
          setFieldValue,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Label>
              Amount
              {errors.amount && <Text color="red">{errors.amount}</Text>}
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.amount}
                border={errors.amount && "1px solid red"}
                type="number"
                name="amount"
                placeholder="Expense Amount"
              />
            </Label>

            <Label>
              Source
              {errors.source && <Text color="red">{errors.source}</Text>}
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.source}
                border={errors.source && "1px solid red"}
                type="text"
                name="source"
                placeholder="Source"
              />
            </Label>

            <Label>
              Expense Type
              <Select
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.type}
                border={errors.type && "1px solid red"}
                type="text"
                name="type"
                placeholder="Expense Amount"
              >
                <option value="Pool Service">Pool Service</option>
                <option value="Repair & Repair">Repair & Repair</option>
                <option value="Lawn & Yard Services">
                  Lawn & Yard Services
                </option>
                <option value="Extra Cleaning">Extra Cleaning</option>
                <option value="Software">Software</option>
                <option value="Tax Filing Fee">Tax Filing Fee</option>
                <option value="Electricity">Electricity</option>
                <option value="Internet">Internet</option>
                <option value="Cables">Cables</option>
                <option value="Water">Water</option>
                <option value="Gas">Gas</option>
                <option value="Mortgage Interest">Mortgage Interest</option>
                <option value="Other">Other</option>
                <option value=""></option>
              </Select>
            </Label>

            <Label>
              Description
              {errors.description && (
                <Text color="red">{errors.description}</Text>
              )}
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                border={errors.description && "1px solid red"}
                type="text"
                name="description"
                placeholder="Description"
              />
            </Label>

            <ButtonsContainer>
              <PrimaryButton type="submit">Save</PrimaryButton>
              {props.init !== null ? (
                <DeleteButton
                  type="button"
                  onClick={async () => {
                    let a = window.confirm(
                      "Are you sure you want to delete this rule?"
                    );
                    if (a) {
                      try {
                        await deleteRule(props.init.ruleId);
                        props.refresh();
                      } catch (e) {
                        console.log(e);
                        context.actions({
                          type: "setState",
                          payload: {
                            ...context.state,
                            isError: true,
                            errorMsg: e.message,
                          },
                        });
                      }
                    }
                  }}
                >
                  DELETE
                </DeleteButton>
              ) : (
                <></>
              )}
            </ButtonsContainer>
          </Form>
        )}
      </Formik>
      {/* END OF FORMIK */}
    </>
  );
}

export default RuleForm;
